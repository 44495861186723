<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
    <ayl-main title="视频文件" :isCollapse="false">
      <div slot="main" class="p24px">
        <div class="content" ref="video">
          <el-image v-if="this.playerOptions.sources === undefined" style="width: 100%;height: 300px;background-color:#eee">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <div v-if="this.playerOptions.sources !== undefined">
            <ayl-video :url='videoUrl'> </ayl-video>
            <!-- <video-js :url="url"></video-js> -->
            <!-- <video-player  class="video-player vjs-custom-skin"
                v-if="this.playerOptions.sources !== undefined"
                ref="videoPlayer"
                :playsinline="true"
                :options="playerOptions"
            ></video-player> -->
          </div>
        </div>
      </div>
    </ayl-main>
    <ayl-main title="图片文件" :isCollapse="false">
      <div slot="main" class="p24px img">
        <div class="left-img">
            <img :src="url===''?playerOptions.images[0]:url" class="images">
        </div>
        <div class="right-img">
          <img v-for="(item, idx) in playerOptions.images" :key="idx" @click="chg(idx)" :src="item"
          class="images">
        </div>
      </div>
    </ayl-main>
    </div>
    <div class="tac" style="margin-top: 40px">
      <el-button style="margin-left: 30px" @click="$router.push({path:'/alarm/alarmDisposeDetails', query: { deviceId: dataFrom.deviceId, alarmStartTime: dataFrom.alarmStartTime, alarmIdentifier: dataFrom.alarmIdentifier, alarmType: dataFrom.alarmType, tenantId: dataFrom.tenantId}})">返回</el-button>
    </div>
  </div>
</template>

<script>
import AylVideo from './components/AylVideo'
import { videoPlayer } from 'vue-video-player'
  export default {
    components: {
      videoPlayer,
      AylVideo
    },
    data () {
      return {
        nav: [
          {name: "报警管理", path: ""},
          {name: "报警处置", path: "/alarm/alarmDispose"},
          {name: "查看详情", path: "/alarm/alarmDisposeDetails", query: {deviceId: this.$route.query.deviceId, alarmStartTime:  this.$route.query.alarmStartTime, alarmIdentifier: this.$route.query.alarmIdentifier, alarmType: this.$route.query.alarmType, tenantId: this.$route.query.tenantId}},
          {name: "预览附件", path: ""},
        ],
        dataFrom:{
          deviceId: this.$route.query.deviceId,
          alarmStartTime: this.$route.query.alarmStartTime,
          alarmIdentifier: this.$route.query.alarmIdentifier,
          alarmType: this.$route.query.alarmType,
          tenantId: this.$route.query.tenantId,
        },
        playerOptions : {
            playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
            autoplay: false, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',
            aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [],
            images: [],
            poster: "", //你的封面地址
            notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true,
              durationDisplay: true,
              remainingTimeDisplay: false,
              fullscreenToggle: true  //全屏按钮
            }
        },
        url: '',
        videoUrl: '',
        form: {
          name: null,
          textarea: null
        },
        submitLoading: false
      }
    },
    methods:{
      chg:function(ind){
        this.url =this.playerOptions.images[ind]
      }
    },
    async mounted () {
      let videoData = await this.$api.findFileByPrefix({
        tenantId: this.$route.query.tenantId,
        attacheKey: this.$route.query.attacheKey
      })
      // console.log(videoData)
      this.playerOptions.images = videoData[0].images
      this.playerOptions.sources = videoData[0].sources
      this.videoUrl = this.playerOptions.sources[0].src
      // console.log(this.videoUrl)
    }
  }
</script>
<style lang='sass' scoped>
.clearBox
  .img
    width: 100%
    .right-img
      width: 20%
      height: 520px
      display: inline-block
      vertical-align: top
      margin-left: 2%
      overflow-y: auto
      .images
        width: 100%
    .left-img
      width: 78%
      height: 520px
      display: inline-block
      .images
        width: 100%
        height: 100%
    .class
      display: block
    .show
      display: none

  .content
    display: inline-block
    width: 100%
    height: 100%
    // /deep/.video-js .vjs-tech
    //   height: 720px
    //   max-height: 720px
  /deep/ .video-js .vjs-big-play-button
    left: 50%
    top: 50%
  /deep/ .content span:nth-child(1)
    color: #fff
</style>
